<template>
    <div v-if="!blog" class="w-full border border-gray-300 flex flex-col gap-8 mt-40 p-8 mx-auto animate-pulse">
        <!-- <cardsLoader/> -->
        <section id="blue-section" class="blue-bar"></section>

        <span class="w-11/12 h-4 bg-gray-300 rounded-full"></span>
        <span class="w-1/3 h-4 bg-gray-300 rounded-full"></span>
        <span class="w-full h-96 bg-gray-300 rounded-md"></span>
        <span class="w-11/12 h-2 bg-gray-300 rounded-full"></span>
        <span class="w-11/12 h-2 bg-gray-300 rounded-full"></span>
        <span class="w-4/5 h-2 bg-gray-300 rounded-full"></span>
        <span class="w-2/3 h-2 bg-gray-300 rounded-full"></span>
        <span class="w-11/12 h-2 bg-gray-300 rounded-full"></span>
        <span class="w-11/12 h-2 bg-gray-300 rounded-full"></span>
        <span class="w-11/12 h-2 bg-gray-300 rounded-full"></span>
    </div>
    <div class="blog-detail-page" v-else>
        <section id="" class="blue-bar">
            <div class="return-container">
                <router-link :to="`/blogs`">
                    <div class="arrow-back">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clip-path="url(#clip0_5052_21893)">
                                <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_5052_21893">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </router-link>

                <div class="note-container">
                    <div class="category">{{ blog.category.category }}</div>
                    <div class="date">{{ getDate(blog.date) }}</div>
                </div>
            </div>
            <h1 class="title-blog">
                {{ blog.title }}
            </h1>
            <span class="author">By {{ blog.author }}.</span>
        </section>
        <!-- <h2 class="w-full  font-semibold text-lg text-gray-400">
            {{  }}
        </h2> -->
        <img loading="lazy" decoding="async" :src="getImgUrl(blog.image)" :alt="blog.title" />
        <section class="blog-content">
            <div class="sidebar">
                <div class="table-list">
                    <span class="title">Table of Content</span>
                    <span v-html="subtopic" class="content-spans" v-for="subtopic in blog.subtopics" :key="subtopic"></span>
                </div>
            </div>
            <div class="paragraph" v-html="blog.text"></div>
            <!-- <p></p> -->
        </section>
        <section class="blog-cards">
            <h2>Other interesting posts</h2>
            <ArticleCardList
                v-for="article in recommendedArticles"
                :key="article._id"
                :id="article._id"
                :author="article.author"
                :image="article.image"
                :description="article.description"
                :title="article.title"
                :category="article.category.category"
                :time="article.reading_time"
                :text="article.text"
                :date="getDate(article.date)"
            />
        </section>
    </div>
</template>

<script>
import { useStore } from "@/store";
import ArticleCardList from "@/components/articleCardList.vue";
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import cardsLoader from '@/components/cardsLoader.vue';
export default {
    name: "DetailBlog",
    components: { ArticleCardList },
    data() {
        return {
            blogId: "",
        };
    },
    mounted() {
        this.blogId = this.$route.params.id;
        this.Store.fetchArticles();
        this.Store.observeIntersection();
    },
    updated() {
        this.Store.observeIntersection();
    },
    computed: {
        blog() {
            return this.Store.getArticleData(this.blogId);
        },
        recommendedArticles() {
            return this.getRandomArticles(this.Store.articles, 4);
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        getRandomArticles(allArticles, count) {
            let shuffled = [...allArticles].sort(() => 0.5 - Math.random());
            return shuffled.slice(0, count);
        },
        getImgUrl(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                return image;
            }
            return require("../assets/Images/user.png");
        },
        getDate(str) {
            const date = new Date(str);

            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
            };

            const formattedDate = date.toLocaleDateString("en-US", options);
            return formattedDate;
        },
    },
};
</script>

<style lang="scss" scoped>
h2 {
    font-size: 2.5rem;
    font-weight: 600;
}
.blog-cards {
    border-top: 1px solid #e3e3e3;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    width: 55%;
    align-items: flex-start;
    gap: 50px;
}
.blog-detail-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
}

.blue-bar {
    border-radius: 0 0 10px 10px;
    background: linear-gradient(180deg, #3361ff 13.03%, #3789ff 70.84%, #00aef0 100%);
    height: 550px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 6rem;
    padding-top: 6rem;
    padding-right: 1rem;
    // padding-right: 4rem;
}

.title-blog {
    color: #fff;
    font-family: Roboto;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    margin-left: 50px;
    line-height: normal;
}

.author {
    color: #fff;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    margin-left: 50px;
    margin-top: 20px;
}

.note-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 40px;
}

.category {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px 0px 0px 6px;
    border: 1px solid #fff;
    background: #3361ff;
    padding: 7px;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
}

img {
    width: 100%;
    height: 120vh;
    object-fit: cover;
    /* Use the desired color for the border */
    border-radius: 0 0 5px 5px;
    margin-top: 0px;
}
.date {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 6px 6px 0px;
    border: 1px solid #fff;
    background: #fff;
    color: #232e52;
    padding: 7px;
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
}

.arrow-back {
    border-radius: 50%;
    background: transparent;
    border: 1px solid #fff;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%);
    width: 37px;
    height: 37px;
    cursor: pointer;
}

.arrow-back:hover {
    opacity: 0.7;
}

.return-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.table-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.table-list .content-spans {
    color: #232e52;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    line-height: 17px;

    /*  &:hover {
        cursor: pointer;
        text-decoration: underline;
    }*/
}

.title {
    color: #00aef0 !important;
    font-family: Roboto;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 20px;
}

.blog-content {
    width: 100%;
    display: flex;
    padding: 0 5rem;
    gap: 5%;
    margin-top: 6rem;
}

.sidebar {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    padding-right: 25px;
    // border-right: 1px solid #d1d5db
}

.paragraph {
    width: 50%;

    ul,
    ol {
        padding-left: 2rem;
        list-style: inherit;
        color: red;
    }
    //p {
    color: #232e52;
    // font-family: Roboto;
    font-size: 15px !important;
    // font-style: normal;
    // font-weight: 400;
    // line-height: normal;
    text-align: start;
    //}

    /* 27px */
}
</style>
